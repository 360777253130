import { putHtmlCodeToDom, HTML_ATTRIBUTE_TRANSFORM_WRAPPER, HTML_ATTRIBUTE_TRANSFORM_WRAPPER_VALUE } from ".";
/**
 * We do need to create a `span` for postscribe cause the <script> tag can not be used in this case.
 *
 * @param html The `script` tag HTML (can be inline or with `src`)
 */

function ensureScriptWrapper(html, node) {
  // Check if there is already a wrapper known
  var previousElementSibling = node.previousElementSibling;
  var referenceNode;

  if (previousElementSibling !== null && previousElementSibling !== void 0 && previousElementSibling.hasAttribute(HTML_ATTRIBUTE_TRANSFORM_WRAPPER)) {
    referenceNode = previousElementSibling;
  } else {
    referenceNode = document.createElement("div"); // we need to have a `block`ing element

    referenceNode.setAttribute(HTML_ATTRIBUTE_TRANSFORM_WRAPPER, HTML_ATTRIBUTE_TRANSFORM_WRAPPER_VALUE);
    node.parentElement.replaceChild(referenceNode, node);
  }

  return putHtmlCodeToDom(html, {}, referenceNode);
}

export { ensureScriptWrapper };