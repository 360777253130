import _extends from "@babel/runtime/helpers/extends";

var BlockerHeroVideoSvgPlayButton = function BlockerHeroVideoSvgPlayButton(_ref) {
  var svgProps = _ref.svgProps,
      fill = _ref.fill;
  return h("svg", _extends({
    width: 68,
    height: 48,
    version: "1.1",
    enableBackground: "new 0 0 595.3 420.2",
    viewBox: "0 0 595.3 420.2"
  }, svgProps, {
    style: {
      cursor: "pointer",
      zIndex: 9
    }
  }), h("path", {
    d: "m582.3 67.8c-6.8-25.6-17.8-47.4-43.4-54.2-46.5-12.5-241.3-13.6-241.3-13.6s-194.7 1.1-241.2 13.6c-25.6 6.8-36.5 28.5-43.4 54.2-12.5 46.4-13 142.3-13 142.3s.5 95.9 13 142.3c6.8 25.6 17.8 47.4 43.4 54.2 46.5 12.4 241.2 13.6 241.2 13.6s194.8-1.1 241.2-13.6c25.6-6.8 36.6-28.5 43.4-54.2 12.4-46.5 13-142.3 13-142.3s-.4-95.9-12.9-142.3z",
    fill: fill
  }), h("path", {
    d: "m401.8 210.1-173.3-96.3v192.6",
    fill: "#fff"
  }));
};

export { BlockerHeroVideoSvgPlayButton };