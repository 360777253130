import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useRef, useState, Fragment } from "react";
import { useRect } from "@reach/rect";
import { useBlocker, useWaitElementInViewport, Portal, CloseIcon, BlockerButtonsInlineStyles, BlockerHeroInlineStyles, BlockerTextContainer, BlockerHeroAudioWaveFromText, BlockerHeroVideoSvgPlayButton } from "../../..";

var BlockerHeroContainer = function BlockerHeroContainer() {
  var blocker = useBlocker();
  var _blocker$headerDesign = blocker.headerDesign,
      fontSize = _blocker$headerDesign.fontSize,
      fontColor = _blocker$headerDesign.fontColor,
      blockerHeadline = blocker.texts.blockerHeadline,
      _blocker$blocker = blocker.blocker,
      presetId = _blocker$blocker.presetId,
      name = _blocker$blocker.name,
      visualHeroButtonText = _blocker$blocker.visualHeroButtonText,
      visualContentType = _blocker$blocker.visualContentType,
      visualThumbnail = _blocker$blocker.visualThumbnail,
      paintMode = blocker.paintMode,
      close = blocker.i18n.close;
  var ref = useRef();
  var rect = useRect(ref);

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isBtnHover = _useState2[0],
      setBtnHover = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isHover = _useState4[0],
      setIsHover = _useState4[1];

  var _useState5 = useState(false),
      _useState6 = _slicedToArray(_useState5, 2),
      isDefaultBlockerVisible = _useState6[0],
      setIsDefaultBlockerVisible = _useState6[1];

  var hide = visualThumbnail.hide;
  var title = visualThumbnail.title || name;
  var audioWaveText = "".concat(visualThumbnail.title, "ThiS iS jUst ANother TEXT TO reACh minIMum length") || blockerHeadline.replace(/{{name}}/g, name); // Lazy load background image

  var isBackgroundVisible = paintMode === "instantInViewport" || useWaitElementInViewport(ref); // Automatically close on outside click

  var overlayRef = useRef();
  var defaultBlockerContainerRef = useRef();
  var handleOutsideClick = useCallback(function (e) {
    if (isDefaultBlockerVisible && [overlayRef.current, defaultBlockerContainerRef.current].indexOf(e.target) > -1) {
      setIsDefaultBlockerVisible(false);
    }
  }, [isDefaultBlockerVisible, overlayRef.current, defaultBlockerContainerRef.current]);
  var hideTitle = (hide === null || hide === void 0 ? void 0 : hide.indexOf("title")) > -1;
  var showPlayButton = ["video-player", "audio-player"].indexOf(visualContentType) > -1;
  var btnAttributes = {
    onMouseEnter: function onMouseEnter() {
      return setBtnHover(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setBtnHover(false);
    },
    onClick: function onClick(e) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      setIsDefaultBlockerVisible(true);
    }
  };
  return h("div", _extends({}, BlockerHeroInlineStyles.aspectRatioWrapper(blocker), {
    ref: ref
  }), isBackgroundVisible && h("div", BlockerHeroInlineStyles.aspectRatioBackgroundWrapper(blocker), h("div", BlockerHeroInlineStyles.aspectRatioBackground(blocker))), h("div", _extends({}, BlockerHeroInlineStyles.aspectRatioWholeClickable(blocker), {
    onMouseEnter: function onMouseEnter() {
      return setIsHover(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setIsHover(false);
    },
    onClick: function onClick() {
      return setIsDefaultBlockerVisible(true);
    }
  })), h("div", BlockerHeroInlineStyles.aspectRatioContainer(blocker), !hideTitle && h("div", _extends({}, BlockerHeroInlineStyles.title(blocker), {
    title: title
  }), title), visualContentType === "audio-player" && h(BlockerHeroAudioWaveFromText, {
    text: audioWaveText
  }), visualHeroButtonText && ["audio-player", "video-player"].indexOf(visualContentType) === -1 ? h("div", _extends({}, BlockerButtonsInlineStyles.hero(blocker, isBtnHover, false), btnAttributes), "\xA0\xA0", visualHeroButtonText, "\xA0\xA0") : showPlayButton && (presetId === "youtube" && visualContentType === "video-player" ? h(BlockerHeroVideoSvgPlayButton, {
    svgProps: btnAttributes,
    fill: isBtnHover || isHover ? "#ff0808" : "rgb(0 0 0 / 70%)"
  }) : h("div", _extends({}, BlockerHeroInlineStyles.playButtonWrapper(blocker, isBtnHover), btnAttributes), h("div", BlockerHeroInlineStyles.playButton(blocker, isBtnHover)))), h(Portal, {
    renderInContainer: document.body
  }, h(Fragment, null, h("div", _extends({}, BlockerHeroInlineStyles.defaultBlockerOverlay(blocker, isDefaultBlockerVisible), {
    ref: overlayRef,
    onClick: handleOutsideClick
  })), h("div", _extends({}, BlockerHeroInlineStyles.defaultBlockerContainer(blocker, isDefaultBlockerVisible, rect), {
    ref: defaultBlockerContainerRef,
    onClick: handleOutsideClick
  }), h(BlockerTextContainer, {
    closeIcon: h(CloseIcon, {
      tooltipText: close,
      width: fontSize,
      color: fontColor,
      thickness: 2,
      onClick: function onClick() {
        return setIsDefaultBlockerVisible(false);
      }
    })
  }))))));
};

export { BlockerHeroContainer };