import { BlockerTextContainer, BlockerHeroContainer, useBlocker } from "../..";

var Blocker = function Blocker() {
  var blocker = useBlocker();
  var _blocker$blocker = blocker.blocker,
      visualType = _blocker$blocker.visualType,
      visualThumbnail = _blocker$blocker.visualThumbnail;

  if (process.env.PLUGIN_CTX === "pro") {
    /* onlypro:start */
    return visualType === "hero" && visualThumbnail ? h(BlockerHeroContainer, null) : h(BlockerTextContainer, null);
    /* onlypro:end */
  } else {
    return h(BlockerTextContainer, null);
  }
};

export { Blocker };